import { createSlice } from "@reduxjs/toolkit";

export const courseSlice = createSlice({
  name: "courseSlice",
  initialState: {
    courseWeeklyAccess: false,
  },
  reducers: {
    handleCourseWeeklyAccess: (state, action) => {
      state.courseWeeklyAccess = action.payload;
    },
  },
});

export const { handleCourseWeeklyAccess } = courseSlice.actions;

export default courseSlice.reducer;
