import { createSlice } from "@reduxjs/toolkit";

export const getAmountSlice = createSlice({
  name: "getAmount",
  initialState: {
    amount: 70,
    dsAmount: 90,
    plan: "monthly",
    monthlyAmount: 14,
    exchangeRate: 1500,
    applicationFee: 10,
    dsExchangeRate: 1500,
    mentorCallSession: 25,
    microSoftAddOnAmount: 20,
    introToDataAnalyticsAmount: 33,
  },
  reducers: {
    handleGetAmount: (state, action) => {
      state.amount = action.payload;
    },
    handleGetDsAmount: (state, action) => {
      state.dsAmount = action.payload;
    },
    handleGetIntroToDataAnalyticsAmountAmount: (state, action) => {
      state.introToDataAnalyticsAmount = action.payload;
    },
    handleSelectPlan: (state, action) => {
      state.plan = action.payload;
    },
    handleExchangeRate: (state, action) => {
      state.exchangeRate = action.payload;
    },
    handleMonthlyAmount: (state, action) => {
      state.monthlyAmount = action.payload;
    },
    handleMicroSoftAddOnAmount: (state, action) => {
      state.microSoftAddOnAmount = action.payload;
    },
  },
});

export const {
  handleGetAmount,
  handleSelectPlan,
  handleGetDsAmount,
  handleExchangeRate,
  handleMonthlyAmount,
  handleMicroSoftAddOnAmount,
  handleGetIntroToDataAnalyticsAmountAmount,
} = getAmountSlice.actions;

export default getAmountSlice.reducer;
