import { configureStore } from "@reduxjs/toolkit";
import windowDimensionReducer from "./reducers/windowDimensionSlice";
import videoContentReducer from "./reducers/videoContentSlice";
import quizSliceReducer from "./reducers/quizSlice";
import getAmountReducer from "./reducers/getAmountSlice";
import setUserSubscriptionReducer from "./reducers/setUserSubscriptionSlice";
import unAuthPaymentReducer from "./reducers/unAuthPaymentSlice";
import countdownTimerReducer from "./reducers/countdownTimerSlice";
import activationFormReducer from "./reducers/activationFormSlice";
import userSettingsSliceReducer from "./reducers/userSettingsSlice";
import timerSliceReducer from "./reducers/timerSlice";
import sessionBlockerSignupSliceReducer from "./reducers/sessionBlockerSignupSlice";
import getCaseStudySliceReducer from "./reducers/getCaseStudySlice";
import toggleSliceReducer from "./reducers/toggleSlice";
import promoSliceReducer from "./reducers/promoSlice";
import bootcampSliceReducer from "./reducers/bootcampSlice";
import activeTabReducer from "./reducers/tabSlice";
import unlockedTopics from "./reducers/unlockTopicSlice";
import showQuizComponent from "./reducers/showQuizComponent";
import showUserFeedback from "./reducers/showUserFeedback";
import bootcampSet from "./reducers/bootcampGraduates";
import bootcampStartDate from "./reducers/bootcampStartDate";
import projectReviews from "./reducers/projectReviews";
import adminSlice from "./reducers/adminSlice";
import lessonRef from "./reducers/lessonRefSlice";
import dashboardData from "./reducers/dashboardDataSlice";
import textBasedLessonMarkup from "./reducers/getTextBasedLessonSlice";
import experiencePointReduce from "./reducers/experiencePointSlice";
import applicationFee from "./reducers/applicationFeeSlice"
import courseAccess from "./reducers/courseSlice"

export default configureStore({
  reducer: {
    windowDimension: windowDimensionReducer,
    videoContent: videoContentReducer,
    showQuiz: quizSliceReducer,
    getAmount: getAmountReducer,
    userSubscription: setUserSubscriptionReducer,
    unAuthPayment: unAuthPaymentReducer,
    dailyCountdownTimer: countdownTimerReducer,
    activationForm: activationFormReducer,
    userSettings: userSettingsSliceReducer,
    promoCountdown: timerSliceReducer,
    sessionBlocker: sessionBlockerSignupSliceReducer,
    getCaseStudy: getCaseStudySliceReducer,
    toggle: toggleSliceReducer,
    promoCode: promoSliceReducer,
    bootcamp: bootcampSliceReducer,
    activeTab: activeTabReducer,
    topics: unlockedTopics,
    quizComponent: showQuizComponent,
    userFeedback: showUserFeedback,
    bootcampGraduates: bootcampSet,
    bootcampStartDate: bootcampStartDate,
    projectReviews: projectReviews,
    admin: adminSlice,
    lessonReferrence: lessonRef,
    dashboardData: dashboardData,
    textBasedLessonMarkup: textBasedLessonMarkup,
    experiencePoint: experiencePointReduce,
    applicationFee: applicationFee,
    courseAccess: courseAccess,
  },
});
