import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const PaymentToggler = ({ paymentType, setPaymentType }) => {
  return (
    <div className="w-full flex mt-5 gap-2 flex-col">
      <div
        className={` flex-1 ${
          paymentType.oneTimePayment
            ? "bg-[#2B2C54] text-white"
            : "bg-[#eaeaea]"
        } rounded relative`}
      >
        {paymentType.oneTimePayment && <SelectedMark />}
        <button
          onClick={() => {
            setPaymentType({
              oneTimePayment: true,
              twoTimesPayment: false,
              threeTimesPayment: false,
            });
          }}
          className="py-3 px-4 text-left font-normal text-lg w-full"
        >
          <span className="block">One Time Payment</span>
          <span className="mt-1 text-xs block font-light">
            100% payment for the bootcamp. Get access to discounts.
          </span>
        </button>
      </div>
      <div
        className={` flex-1 ${
          paymentType.twoTimesPayment
            ? "bg-[#2B2C54] text-white"
            : "bg-[#eaeaea]"
        } rounded relative`}
      >
        {paymentType.twoTimesPayment && <SelectedMark />}
        <button
          onClick={() => {
            setPaymentType({
              oneTimePayment: false,
              twoTimesPayment: true,
              threeTimesPayment: false,
            });
          }}
          className="py-3 px-4 text-left font-normal text-lg w-full"
        >
          <span className="block">Pay Two Times</span>
          <span className="mt-1 text-xs block font-light">
            Pay 50% now and complete it in 30 days.
          </span>
        </button>
      </div>
      <div
        className={` flex-1 ${
          paymentType.threeTimesPayment
            ? "bg-[#2B2C54] text-white"
            : "bg-[#eaeaea]"
        } rounded relative`}
      >
        {paymentType.threeTimesPayment && <SelectedMark />}
        <button
          onClick={() => {
            setPaymentType({
              oneTimePayment: false,
              twoTimesPayment: false,
              threeTimesPayment: true,
            });
          }}
          className="py-3 px-4 text-left font-normal text-lg w-full"
        >
          <span className="block">Pay Three Times</span>
          <span className="mt-1 text-xs block font-light">
            Pay 40% now, followed by two monthly installments of 30%
            each.
          </span>
        </button>
      </div>
    </div>
  );
};

const SelectedMark = () => (
  <span className="text-xs font-bold px-2 py-1 bg-white rounded-full w-4 h-4 flex items-center justify-center gap-1 absolute -left-1 -top-1">
    <CheckCircleIcon className="text-[#ff8c00]" />
  </span>
);
