import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../context/AuthContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  ReviewCard,
  CourseDetails,
  StudentComment,
  StudentDetails,
  AdditionalFeedback,
  ProjectDownloadLink,
  SubmitProjectReview,
} from "../../components/ProjectSubmission/ProjectSubmission";
import AdminDisplayName from "../../components/Admin/AdminDIsplayName";
import AppNotification from "../../components/AppNotification/AppNotification";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { handleFilteredProjectsToReview } from "../../redux/reducers/projectReviews";

const base_url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : process.env.NODE_ENV === "development" &&
      "http://localhost:8000";

const ProjectSubmission = () => {
  useScrollToTop();
  const { docId } = useParams();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const redirect = useNavigate();
  const [status, setStatus] = useState({
    approved: false,
    revisionRequired: false,
  });
  const [addStory, setAddStory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [appNotificationText, setAppNotificationText] = useState("");

  const filteredProjectsToReview = JSON.parse(
    useSelector(
      (state) => state.projectReviews.filteredProjectsToReview
    )
  );

  const review = {
    ...(additionalFeedback && { additionalFeedback }),
    addStory,
    reviewer: currentUser?.email,
    status: status.approved
      ? "Approved"
      : status.revisionRequired
      ? "Revision required"
      : "",
  };

  const handleSubmitProject = async () => {
    if (!projectDetails) return;

    if (!review.status) {
      setAppNotificationText(
        'Click on "Approved" or "Revision required" to review project'
      );
      setTimeout(() => setAppNotificationText(""), 3000);
      return;
    }
    try {
      setIsLoading(true);
      const docRef = doc(db, "assignments", docId);
      const docData = (await getDoc(docRef)).data();
      const body = JSON.stringify({
        email: docData.email,
        username: docData.fullName,
        courseTitle: docData.courseTitle,
        ...(additionalFeedback && { additionalFeedback }),
        status: status.approved ? "approved" : "revision required",
      });
      await updateDoc(docRef, review);
      await fetch(`${base_url}/api/v1/project-review`, {
        method: "POST",
        body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      setIsLoading(false);

      // Filter out reviewed project
      const filteredList =
        status.revisionRequired || status.approved
          ? filteredProjectsToReview.filter(
              (project) => project?.docId !== projectDetails?.docId
            )
          : filteredProjectsToReview;
      dispatch(
        handleFilteredProjectsToReview(JSON.stringify(filteredList))
      );

      // Redirect to submission table page
      setAppNotificationText("Review updated successfully");
      setTimeout(() => {
        setAppNotificationText("");
        redirect("/dashboard/admin");
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      setAppNotificationText("Error updating document");
      setTimeout(() => setAppNotificationText(""), 3000);
    }
  };

  useEffect(() => {
    const getAssignmentDocument = async () => {
      const docRef = doc(db, "assignments", docId);
      try {
        const documentSnapshot = await getDoc(docRef);

        if (documentSnapshot.exists()) {
          const documentData = documentSnapshot.data();
          const docId = documentSnapshot?.id;
          setAddStory(documentData?.addStory ? true : false);
          setProjectDetails({ ...documentData, docId });
          setStatus({
            approved: documentData?.status === "Approved",
            revisionRequired:
              documentData?.status === "Revision required",
          });
          if (documentData?.additionalFeedback) {
            setAdditionalFeedback(documentData?.additionalFeedback);
          }
        }
      } catch (error) {
        setAppNotificationText("Error getting document");
        setTimeout(() => setAppNotificationText(""), 6000);
      }
    };
    getAssignmentDocument();
  }, []);

  return (
    <div className="px-4 mb-40">
      {appNotificationText && (
        <div className="fixed w-full left-0">
          <div className="mx-auto w-fit">
            <AppNotification text={appNotificationText} />
          </div>
        </div>
      )}
      <div className="max-w-[68.75rem] w-full mx-auto">
        <AdminDisplayName
          url="dashboard/admin"
          displayName={currentUser?.displayName}
        />

        <StudentDetails projectDetails={projectDetails} />
        <CourseDetails projectDetails={projectDetails} />
        <ProjectDownloadLink projectDetails={projectDetails} />
        <StudentComment projectDetails={projectDetails} />

        <div className="mt-10">
          <ReviewCard
            status={status}
            setStatus={setStatus}
          />

          <AdditionalFeedback
            addStory={addStory}
            setAddStory={setAddStory}
            additionalFeedback={additionalFeedback}
            setAdditionalFeedback={setAdditionalFeedback}
          />

          <SubmitProjectReview
            isLoading={isLoading}
            handleSubmitProject={handleSubmitProject}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectSubmission;
