import * as Yup from "yup";
import {
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  where,
  query,
  getDocs,
  Timestamp,
  collection,
} from "firebase/firestore";
import "firebase/compat/auth";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { PaystackButton } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";

// Constants
import { discountArray } from "../../constants/bootcampDiscountCodes";

import {
  handleDataScienceSelected,
  handleDataAnalyticsSelected,
  handleSuccessNotificationStatus,
  handleIntroToDataAnalyticsSelected,
  handleSelectCohort,
  handleDataScienceCohort,
  handleIntroToDataAnalyticsCohort,
} from "../../redux/reducers/bootcampSlice";
import { useAuth } from "../../context/AuthContext";
import { analytics, db } from "../../firebase/firebase";
import {
  handleExchangeRate,
  handleGetAmount,
  handleGetDsAmount,
} from "../../redux/reducers/getAmountSlice";

// @components
import Loader from "../../utils/Loader/Loader";
import Buttons from "../../components/Buttons/Buttons";
import useScrollToTop from "../../hooks/useScrollToTop";
import { SectionLayout } from "../../utils/SectionLayout";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import ToggleSwitch from "../../utils/ToogleSwitch/ToggleSwitch";
import AppNotification from "../../components/AppNotification/AppNotification";
import BootcampSignupDetails from "../../components/BootcampSignupDetails/BootcampSignupDetails";
import { PaymentToggler } from "./PaymentToggler";
import {
  setApplyDiscount,
  setApppliedCodesArr,
  setDiscountCodeValue,
} from "../../redux/reducers/promoSlice";
import { PlanSubscriptionModal } from "./PlanSubscriptionModal";
import { customRound } from "./helper/helper";
import { handleBootcampStartDate } from "../../redux/reducers/bootcampStartDate";
import { TextSkeletonLoader } from "../../components/BootcampRoadmap/utils";

const discount = 0.8;
const discount_code = ["KITT20"];

const base_url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : process.env.NODE_ENV === "development" &&
      "http://localhost:8000";

const BootcampPayment = () => {
  useScrollToTop();
  const TEN_PERCENT_INCREASE = 1.1;
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState({
    oneTimePayment: true,
    twoTimesPayment: false,
    threeTimesPayment: false,
  });
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showPlanSubModal, setShowPlanSubModal] = useState(false);
  const [showFillFormText, setShowFillFormText] = useState(false);
  const [bootcamperLastname, setBootcamperLastname] = useState("");
  const [bootcamperFirstname, setBootcamperFirstname] = useState("");

  const { currentUser } = useAuth();
  const [searchParams] = useSearchParams();

  const referralLink = searchParams.get("ref-id");

  const [bootcampType, setBootcampType] = useState({
    dataScience: false,
    dataAnalytics: false,
    introToDataAnalytics: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const codeApplied = useSelector(
    (state) => state.promoCode.codeApplied
  );
  const exchangeRate = useSelector((state) =>
    bootcampType.dataScience
      ? state.getAmount.dsExchangeRate
      : state.getAmount.exchangeRate
  );
  const selectedCohort = useSelector(
    (state) => state.bootcamp.selectCohort
  );
  const discountCodeUsed = useSelector(
    (state) => state.promoCode.discountCode
  );
  const dataScienceCohort = useSelector(
    (state) => state.bootcamp.dataScienceCohort
  );
  const introToDataAnalyticsCohort = useSelector(
    (state) => state.bootcamp.introToDataAnalyticsCohort
  );
  const selectedBootcampStartDate = useSelector(
    (state) => state.bootcamp.bootcampStartDate
  );
  const toggle = useSelector((state) => state.toggle.toggle);
  const amount = useSelector((state) =>
    bootcampType.dataScience
      ? state.getAmount.dsAmount
      : bootcampType.introToDataAnalytics
      ? state.getAmount.introToDataAnalyticsAmount
      : state.getAmount.amount
  );

  const uid = currentUser?.uid;
  const userEmail = currentUser?.email;
  const FEE_FOR_PAYING_TWICE = 1;
  const FIX_MS_OFFICE_AMOUNT_IN_USD = 20;
  const FIX_MS_OFFICE_AMOUNT_IN_NAIRA = 20000;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePaystackCloseAction = () => {
    return;
  };

  const getDateOneMonthFromToday = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
  };

  const handlePaystackSuccessAction = () => {
    navigate("/dashboard");
    dispatch(handleSuccessNotificationStatus(true));
    localStorage.removeItem("refId");
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      confirmEmail: "",
      password: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required(
        "Please enter your first name"
      ),
      lastName: Yup.string().required("Please enter your last name"),
      email: Yup.string().required("Please enter your email"),
      phoneNumber: Yup.string().required(
        "Please enter your phone number"
      ),
      confirmEmail: Yup.string()
        .email("Invalid email address")
        .oneOf([Yup.ref("email"), null], "Emails must match")
        .required("Confirm email is required"),
      password: Yup.string()
        .required("Please enter a valid password")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ),
    }),
  });

  const custom_fields = [
    {
      display_name: "Customer's name",
      variable_name: "customer_name",
      value: `${
        currentUser ? bootcamperFirstname : formik.values.firstName
      } ${currentUser ? bootcamperLastname : formik.values.lastName}`,
    },
  ];

  const getRefId = () =>
    referralLink ||
    (localStorage.getItem("refId") &&
      JSON.parse(localStorage.getItem("refId")));

  const data = {
    ...(getRefId() && { referrerDocId: getRefId() }),
    phoneNumber: formik.values.phoneNumber,
    bootcampType: bootcampType.dataAnalytics
      ? "Data Analytics"
      : bootcampType.introToDataAnalytics
      ? "Intro To Data Analytics"
      : "Data Science",
    userId: currentUser && uid,
    parsedTimestamp: Date.now(),
    isDiscountUsed: codeApplied,
    codeApplied: discountCodeUsed,
    selectedCohort: bootcampType.dataScience
      ? dataScienceCohort
      : bootcampType.introToDataAnalytics
      ? introToDataAnalyticsCohort
      : selectedCohort,
    currency: toggle ? "USD" : "NGN",
    firestoreTimestamp: Timestamp.now(),
    ...(isChecked && { msOffice: toggle ? 20 : 20000 }),
    email: userEmail ?? formik.values.email.toLowerCase(),
    bootcampStartDateString: selectedBootcampStartDate,
    lastName: currentUser
      ? bootcamperLastname
      : formik.values.lastName,
    firstName: currentUser
      ? bootcamperFirstname
      : formik.values.firstName,
  };

  const bootcampData = {
    ...data,
    amount: toggle
      ? customRound(
          amount + (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)
        )
      : customRound(
          amount * exchangeRate +
            (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)
        ),
  };
  const paymentPlanBootcampData = {
    ...data,
    paidFull: false,
    paymentRetry: 0,
    amount: toggle
      ? customRound(
          paymentType.threeTimesPayment
            ? amount * TEN_PERCENT_INCREASE * 0.4
            : ((amount +
                (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)) *
                FEE_FOR_PAYING_TWICE) /
                2
        )
      : customRound(
          paymentType.threeTimesPayment
            ? amount * TEN_PERCENT_INCREASE * 0.4 * exchangeRate
            : ((amount * exchangeRate +
                (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)) *
                FEE_FOR_PAYING_TWICE) /
                2
        ),
    nextPayDate: getDateOneMonthFromToday(new Date(), 1),
    ...(!paymentType.threeTimesPayment && { plan: "Installment" }),
    ...(paymentType.threeTimesPayment && { threePartPayment: true }),
    ...(paymentType.threeTimesPayment && {
      partPaymentCompletion: 1,
    }),
  };

  const configObj = {
    reference: uuidv4(),
    currency: toggle ? "USD" : "NGN",
    onClose: handlePaystackCloseAction,
    onSuccess: () => handlePaystackSuccessAction(),
    publicKey:
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PAYSTACK_PUBLIC_LIVE_KEY
        : process.env.NODE_ENV === "development" &&
          process.env.REACT_APP_PAYSTACK_PUBLIC_TEST_KEY,
    email: currentUser ? currentUser?.email : formik.values.email,
  };

  const config = {
    ...configObj,
    amount: toggle
      ? customRound(
          customRound(
            amount + (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)
          ) * 100
        )
      : customRound(
          customRound(
            amount * exchangeRate +
              (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)
          ) * 100
        ),
    metadata: {
      bootcampData,
      custom_fields,
      currentUser: currentUser ? true : false,
      ...(!currentUser && { password: formik.values.password }),
    },
  };

  const componentProps = {
    ...config,
    text: `${
      amount !== ""
        ? `Pay ${toggle ? "USD" : "NGN"} ${
            toggle
              ? customRound(
                  amount +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)
                ).toLocaleString()
              : customRound(
                  amount * exchangeRate +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)
                ).toLocaleString()
          } ${isChecked ? "(MS Office inclusive)" : ""}`
        : "Proceed with payment"
    }`,
  };

  const paymentPlanConfig = {
    ...configObj,
    channels: ["card"],
    metadata: {
      custom_fields,
      bootcampData: paymentPlanBootcampData,
      currentUser: currentUser ? true : false,
      ...(!currentUser && { password: formik.values.password }),
    },
  };

  useEffect(() => {
    const refreshTime = 6 * 60 * 60 * 1000;
    const interval = setInterval(async () => {
      try {
        const res = await fetch(`${base_url}/api/v1/exchange-rate`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const resData = await res.json();
        if (typeof resData.rate === "number") {
          dispatch(handleExchangeRate(resData.rate + 200));
        }
      } catch (error) {
        console.log(error);
      }
    }, refreshTime);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Resets the price
    const ACTUAL_AMOUNT = bootcampType.dataScience ? 90 : 70;

    return () => {
      dispatch(setApplyDiscount(null));
      dispatch(setDiscountCodeValue(""));
      dispatch(setApppliedCodesArr([]));
      bootcampType.dataScience
        ? dispatch(handleGetDsAmount(ACTUAL_AMOUNT))
        : dispatch(handleGetAmount(ACTUAL_AMOUNT));
    };
  }, [dispatch, bootcampType]);

  useEffect(() => {
    if (codeApplied) {
      return amount;
    } else {
      if (bootcampType.dataScience) {
        dispatch(handleDataScienceSelected(true));
        dispatch(handleDataAnalyticsSelected(false));
        dispatch(handleIntroToDataAnalyticsSelected(false));
        setBootcampType({
          dataScience: true,
          dataAnalytics: false,
          introToDataAnalytics: false,
        });
      } else if (bootcampType.introToDataAnalytics) {
        dispatch(handleDataScienceSelected(false));
        dispatch(handleDataAnalyticsSelected(false));
        dispatch(handleIntroToDataAnalyticsSelected(true));
        setBootcampType({
          dataScience: false,
          dataAnalytics: false,
          introToDataAnalytics: true,
        });
      } else if (bootcampType.dataAnalytics) {
        dispatch(handleDataScienceSelected(false));
        dispatch(handleDataAnalyticsSelected(true));
        dispatch(handleIntroToDataAnalyticsSelected(false));
        setBootcampType({
          dataScience: false,
          dataAnalytics: true,
          introToDataAnalytics: false,
        });
      }
    }

    const fetchUserDetails = async () => {
      try {
        const q = query(
          collection(db, "applicationFees"),
          where("email", "==", userEmail)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          setBootcamperLastname(doc.data().lastName);
          setBootcamperFirstname(doc.data().firstName);
          const bootcampType = doc?.data()?.bootcampType;
          const selectedCohort = doc?.data()?.selectedCohort;
          const bootcampStartDateString =
            doc?.data()?.bootcampStartDateString;
          if (bootcampType === "Data Analytics") {
            setBootcampType({
              dataScience: false,
              dataAnalytics: true,
              introToDataAnalytics: false,
            });
            dispatch(handleSelectCohort(+selectedCohort));
            dispatch(handleDataScienceSelected(false));
            dispatch(handleDataAnalyticsSelected(true));
            dispatch(handleIntroToDataAnalyticsSelected(false));
          } else if (bootcampType === "Data Science") {
            setBootcampType({
              dataScience: true,
              dataAnalytics: false,
              introToDataAnalytics: false,
            });
            dispatch(handleDataScienceCohort(+selectedCohort));
            dispatch(handleDataScienceSelected(true));
            dispatch(handleDataAnalyticsSelected(false));
            dispatch(handleIntroToDataAnalyticsSelected(false));
          } else if (bootcampType === "Intro To Data Analytics") {
            setBootcampType({
              dataScience: false,
              dataAnalytics: false,
              introToDataAnalytics: true,
            });
            dispatch(handleDataScienceSelected(false));
            dispatch(handleDataAnalyticsSelected(false));
            dispatch(handleIntroToDataAnalyticsSelected(true));
            dispatch(
              handleIntroToDataAnalyticsCohort(selectedCohort)
            );
          }
          dispatch(handleBootcampStartDate(bootcampStartDateString));
          if (doc.data()?.phoneNumber) {
            formik.setFieldValue(
              "phoneNumber",
              doc.data()?.phoneNumber
            );
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    if (currentUser) {
      fetchUserDetails();
    }
  }, [dispatch, currentUser, userEmail, amount, codeApplied]);

  const isFormFillComplete =
    (!currentUser && formik.values.firstName === "") ||
    (!currentUser && formik.values.lastName === "") ||
    (!currentUser && formik.values.phoneNumber === "") ||
    (!currentUser && formik.values.email === "") ||
    (!currentUser && formik.values.confirmEmail === "") ||
    (!currentUser && formik.values.password === "") ||
    (currentUser && formik.values.phoneNumber === "") ||
    (!currentUser &&
      formik.values.email !== formik.values.confirmEmail) ||
    (!bootcampType.dataAnalytics &&
      !bootcampType.dataScience &&
      !bootcampType.introToDataAnalytics);

  const showUnauthWarningMsg = () => {
    setShowSuccessToast(true);
    setShowFillFormText(true);
    setTimeout(() => {
      setShowSuccessToast(false);
      setShowFillFormText(false);
    }, 2000);
  };

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCheck();
    }
  };

  const saveRefIdToLocalStorage = () => {
    if (referralLink) {
      localStorage.setItem("refId", JSON.stringify(referralLink));
    }
  };

  return (
    <>
      <MetaTags
        path={"pricing"}
        title={"Bootcamp Payment | Resagratia"}
        desc={
          "You are about to sign up for the Resagratia data bootcamp cohort."
        }
        xPreviewImageLink="https://storage.googleapis.com/resagratia-webapp/misc/twitter_bootcamp_signup.png"
        fbPreviewImageLink="https://storage.googleapis.com/resagratia-webapp/misc/facebook_bootcamp_signup.png"
      />
      {loading && <Loader />}
      {showSuccessToast === true && (
        <div className="fixed left-0 right-0 top-[20%] z-[20000] xs:px-4 md:px-0">
          <AppNotification
            text={`${
              showFillFormText
                ? "Your data is incomplete, refresh your page to fetch data"
                : "We're setting up your account. Please do not leave or close tab."
            }`}
          />
        </div>
      )}
      <SectionLayout style="pb-[8.5rem] bg-[#FBFBFB] py-6 px-4 pt-6">
        <div className="w-full max-w-[65rem] mx-auto flex gap-16 max-[800px]:gap-8 max-[800px]:flex-col">
          <div className="flex-1">
            <h2 className="font-normal text-[2.21775rem] max-w-[25rem] mb-[1.7742rem] leading-[2.6rem] sectionTitle mt-8">
              You are enrolling in the{" "}
              <span className="text-[#FF8C00]">
                {bootcampType.dataAnalytics ? (
                  "Data Analytics"
                ) : bootcampType.introToDataAnalytics ? (
                  "Introduction To Data Analytics"
                ) : bootcampType.dataScience ? (
                  "Data Science"
                ) : (
                  <TextSkeletonLoader
                    fontSize
                    colour={false}
                    width={`max-w-[13.7rem]`}
                  />
                )}{" "}
              </span>
              {bootcampType.introToDataAnalytics
                ? "Course Track"
                : "Bootcamp"}
            </h2>

            {!currentUser && (
              <div className="mb-6">
                <span className="text-sm">
                  <button className="text-[#2b2c54] font-bold">
                    <Link
                      to="/signin"
                      state={{ previousUrl: location.pathname }}
                    >
                      Sign in
                    </Link>
                  </button>{" "}
                  if you have an account.
                </span>
              </div>
            )}
            {!bootcampType.introToDataAnalytics && (
              <PaymentToggler
                paymentType={paymentType}
                setPaymentType={setPaymentType}
              />
            )}
          </div>

          <div className="flex-1 max-[800px]:px-2">
            <div className="">
              <BootcampSignupDetails
                bootcampSignUp
                amount={amount}
                discount={discount}
                paymentType={paymentType}
                bootcampType={bootcampType}
                discountArr={discount_code}
                discountArray={discountArray}
              />
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex flex-row justify-center">
                <ToggleSwitch
                  leftText="Pay in Naira (₦)"
                  rightText="Pay in USD ($)"
                />
              </div>
              {paymentType.oneTimePayment ? (
                <div className="xs:w-full">
                  {isFormFillComplete ? (
                    <Buttons
                      onClick={() => {
                        if (isFormFillComplete) {
                          showUnauthWarningMsg();
                          return;
                        }
                      }}
                      style={`bg-[#f1f3f4] text-sm text-[#111] w-full py-3 px-2 font-bold w-full`}
                    >
                      {amount !== ""
                        ? `Pay ${toggle ? "USD" : "NGN"} ${
                            toggle
                              ? customRound(amount).toLocaleString()
                              : customRound(
                                  amount * exchangeRate
                                ).toLocaleString()
                          }`
                        : "Proceed with payment"}
                    </Buttons>
                  ) : (
                    <PaystackButton
                      {...componentProps}
                      className="rounded w-full bg-[#2b2c54] py-3 px-2 text-white text-sm hover:bg-[#15175c]"
                    />
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    if (isFormFillComplete) {
                      showUnauthWarningMsg();
                      return;
                    }
                    logEvent(
                      analytics,
                      "bootcamp_payment_plan_button_clicked",
                      {
                        from_url: location.pathname,
                      }
                    );
                    setShowPlanSubModal(!showPlanSubModal);
                  }}
                  className={`${
                    isFormFillComplete
                      ? "bg-[#f1f3f4] text-black cursor-not-allowed"
                      : "bg-[#2b2c54] text-white hover:bg-[#15175c]"
                  } rounded w-full bg-[#2b2c54] py-3 px-2 text-sm font-bold`}
                >
                  🔒 Proceed with payment
                </button>
              )}
              {showPlanSubModal && (
                <PlanSubscriptionModal
                  toggle={toggle}
                  isChecked={isChecked}
                  paymentType={paymentType}
                  FIX_MS_OFFICE_AMOUNT_IN_USD={
                    FIX_MS_OFFICE_AMOUNT_IN_USD
                  }
                  FIX_MS_OFFICE_AMOUNT_IN_NAIRA={
                    FIX_MS_OFFICE_AMOUNT_IN_NAIRA
                  }
                  paymentPlanConfig={paymentPlanConfig}
                  isFormFillComplete={isFormFillComplete}
                  TEN_PERCENT_INCREASE={TEN_PERCENT_INCREASE}
                  FEE_FOR_PAYING_TWICE={FEE_FOR_PAYING_TWICE}
                  setShowPlanSubModal={setShowPlanSubModal}
                />
              )}
            </div>
          </div>
        </div>
      </SectionLayout>
    </>
  );
};

export default BootcampPayment;
