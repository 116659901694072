import { createSlice } from "@reduxjs/toolkit";

export const bootcampSlice = createSlice({
  name: "bootcamp",
  initialState: {
    selectCohort: 1,
    isBootcamper: false,
    dataScienceCohort: 1,
    bootcampStartDate: ``,
    dataScienceSelected: false,
    dataAnalyticsSelected: true,
    introToDataAnalyticsCohort: 1,
    successNotificationStatus: false,
    introToDataAnalyticsSelected: false,
  },
  reducers: {
    handleSelectCohort: (state, action) => {
      state.selectCohort = action.payload;
    },
    handleIsBootcamper: (state, action) => {
      state.isBootcamper = action.payload;
    },
    handleDataScienceCohort: (state, action) => {
      state.dataScienceCohort = action.payload;
    },
    handleIntroToDataAnalyticsCohort: (state, action) => {
      state.introToDataAnalyticsCohort = action.payload;
    },
    handleStoreBootcampDate: (state, action) => {
      state.bootcampStartDate = action.payload;
    },
    handleDataScienceSelected: (state, action) => {
      state.dataScienceSelected = action.payload;
    },
    handleDataAnalyticsSelected: (state, action) => {
      state.dataAnalyticsSelected = action.payload;
    },
    handleIntroToDataAnalyticsSelected: (state, action) => {
      state.introToDataAnalyticsSelected = action.payload;
    },
    handleSuccessNotificationStatus: (state, action) => {
      state.successNotificationStatus = action.payload;
    },
  },
});

export const {
  handleSelectCohort,
  handleIsBootcamper,
  handleStoreBootcampDate,
  handleDataScienceCohort,
  handleDataScienceSelected,
  handleDataAnalyticsSelected,
  handleSuccessNotificationStatus,
  handleIntroToDataAnalyticsCohort,
  handleIntroToDataAnalyticsSelected,
} = bootcampSlice.actions;

export default bootcampSlice.reducer;
