import { Link } from "react-router-dom";
import { useRef, useState } from "react";

const ReferralLink = ({ profile, width, showDesc }) => {
  const localRef = useRef(null);
  const [hasCopiedRefLink, setHasCopiedRefLink] = useState(false);

  let refLinkText;

  const handleCopyRefLink = async () => {
    try {
      refLinkText = `https://resagratia.com/bootcamp/paths?ref-id=${profile?.docId}`;
      await navigator.clipboard.writeText(refLinkText);

      if (localRef.current) {
        setHasCopiedRefLink(true);

        setTimeout(() => {
          setHasCopiedRefLink(false);
        }, 6000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      ref={localRef}
      className={`flex flex-row items-center gap-3 xs:w-full ${
        width ? width : "md:w-1/3"
      } relative rounded`}
    >
      {showDesc && (
        <div className="inset-0 bg-white absolute flex items-center">
          <p>
            Refer us and get 1-month free with{" "}
            <span
              onClick={handleCopyRefLink}
              className="bg-orange-300 p-[2px] px-1 rounded font-normal cursor-pointer"
            >
              this link
            </span>{" "}
          </p>
        </div>
      )}
      <span className="font-medium text-[#808080] text-sm">
        Referral link:
      </span>
      <div
        onClick={handleCopyRefLink}
        className="overflow-auto scrollbar flex-1 bg-[#f6f7f8] px-2 py-2 rounded-lg"
      >
        <Link
          to={`/bootcamp/paths?ref-id=${profile?.docId}`}
          className="block whitespace-nowrap text-sm text-gray-800 underline"
        >{`https://resagratia.com/bootcamp/paths?ref-id=${profile?.docId}`}</Link>
        <span
          className={`absolute -top-6 py-0.5 right-0 ${
            hasCopiedRefLink
              ? "bg-[#2b2c54] border-[#2b2c54] border"
              : "bg-gray-500"
          } max-[290px]:text-[9px] rounded px-2 border text-white border-white text-xs font-bold hover:bg-[#2b2c54] hover:text-white hover:border-[#2b2c54] cursor-pointer`}
        >
          {hasCopiedRefLink
            ? (refLinkText = "Copied!")
            : (refLinkText = "Copy")}
        </span>
      </div>
    </div>
  );
};

export default ReferralLink;
