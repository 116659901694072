import { createSlice } from "@reduxjs/toolkit";

export const bootcampStartDateSlice = createSlice({
  name: "bootcampStartDate",
  initialState: {
    bootcampStartDate: null,
    extendedBootcampStartDate: null,
    bootCampRoadmap: JSON.stringify([]),
  },
  reducers: {
    handleBootcampStartDate: (state, action) => {
      state.bootcampStartDate = action.payload;
    },
    handleBootCampRoadmap: (state, action) => {
      state.bootCampRoadmap = action.payload;
    },
    handleExtendedBootcampStartDate: (state, action) => {
      state.extendedBootcampStartDate = action.payload;
    },
  },
});

export const {
  handleBootCampRoadmap,
  handleBootcampStartDate,
  handleExtendedBootcampStartDate,
} = bootcampStartDateSlice.actions;
export default bootcampStartDateSlice.reducer;
